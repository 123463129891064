body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
}
::-webkit-scrollbar-track {
  background: #000E20;
}
::-webkit-scrollbar-corner {
  background: #000E20;
  width: 0;
  height: 0;
}

.MuiToolbar-regular {
  /* TODO hack */
  background-color: #000E20;
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.12);
}

.MuiButton-sizeSmall {
  /* TODO hack */
  border-radius: 100px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.MuiButton-sizeMedium {
  /* TODO hack */
  border-radius: 100px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.MuiInputBase-root {
  /* TODO hack */
  border-radius: 12px !important;
}

.MuiPaper-rounded {
  /* TODO hack */
  border-radius: 12px !important;
}

li {
  margin: 10px 0;
}